<template>
  <transition name="fade">
    <section class="main-wrapper">
      <Header />
      <router-view/>
    </section>
  </transition>
</template>

<script>
import { mapActions } from 'vuex'
import Header from '@/components/Header'

export default {
  name: 'default',
  components: { Header },
  methods: {
    ...mapActions(['fetchUser', 'fetchAvatars', 'fetchCalendar'])
  },
  async created() {
    await this.fetchUser();
    await this.fetchAvatars();
    await this.fetchCalendar(this.$moment(new Date()).format('YYYY'));
  }
}
</script>

<style lang="scss" scoped>
.main-wrapper { 
  background-color: #000; 
  min-height: 100vh;
  min-width: 100vw;
}
</style>
